"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftActivity = void 0;
const enums_1 = require("@bemlo/enums");
exports.shiftActivity = {
    [enums_1.ShiftActivity.WORK]: 'Arbeid',
    [enums_1.ShiftActivity.FLEX]: 'Resurspass',
    [enums_1.ShiftActivity.TEAM_MEETING]: 'Møte',
    [enums_1.ShiftActivity.TRAINING]: 'Outvikling',
    [enums_1.ShiftActivity.INTRODUCTION]: 'Introduksjon',
    [enums_1.ShiftActivity.OTHER]: 'Annet',
};
