"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftActivity = void 0;
const enums_1 = require("@bemlo/enums");
exports.shiftActivity = {
    [enums_1.ShiftActivity.WORK]: 'Work',
    [enums_1.ShiftActivity.FLEX]: 'Flex',
    [enums_1.ShiftActivity.TEAM_MEETING]: 'Team meeting',
    [enums_1.ShiftActivity.TRAINING]: 'Training',
    [enums_1.ShiftActivity.INTRODUCTION]: 'Introduction',
    [enums_1.ShiftActivity.OTHER]: 'Other',
};
