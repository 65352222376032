"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStyles = void 0;
const theme_1 = require("../constants/theme");
const parseBorderShorthandProps = (props) => {
    return {
        ...(props.rounded === true && {
            borderRadius: theme_1.theme.border.radius.normal,
        }),
        ...((props.rounded === 'b' || props.rounded === 'bl') && {
            borderBottomLeftRadius: theme_1.theme.border.radius.normal,
        }),
        ...((props.rounded === 'b' || props.rounded === 'br') && {
            borderBottomRightRadius: theme_1.theme.border.radius.normal,
        }),
        ...((props.rounded === 't' || props.rounded === 'tl') && {
            borderTopLeftRadius: theme_1.theme.border.radius.normal,
        }),
        ...((props.rounded === 't' || props.rounded === 'tr') && {
            borderTopRightRadius: theme_1.theme.border.radius.normal,
        }),
    };
};
const parseFlexProps = (props) => {
    switch (props.flex) {
        case 'col':
            return { display: 'flex', flexDirection: 'column' };
        case 'col-reverse':
            return { display: 'flex', flexDirection: 'column-reverse' };
        case 'row':
            return { display: 'flex', flexDirection: 'row' };
        case 'row-reverse':
            return { display: 'flex', flexDirection: 'row-reverse' };
        default:
            return props.flex ? { flex: props.flex } : {};
    }
};
const parseGapProps = (props) => {
    return {
        ...(props.columnGap !== undefined && {
            columnGap: parseSpacingValue(props.columnGap),
        }),
        ...(props.gap !== undefined && {
            gap: parseSpacingValue(props.gap),
        }),
        ...(props.rowGap !== undefined && {
            rowGap: parseSpacingValue(props.rowGap),
        }),
    };
};
const parseSpacingShorthandProps = (props) => {
    const height = parseSpacingValue(props.h);
    const margin = parseSpacingValue(props.m);
    const marginBottom = parseSpacingValue(props.mb ?? props.my);
    const marginLeft = parseSpacingValue(props.ml ?? props.mx);
    const marginRight = parseSpacingValue(props.mr ?? props.mx);
    const marginTop = parseSpacingValue(props.mt ?? props.my);
    const maxHeight = parseSpacingValue(props.maxH);
    const maxWidth = parseSpacingValue(props.maxW);
    const padding = parseSpacingValue(props.p);
    const paddingBottom = parseSpacingValue(props.pb ?? props.py);
    const paddingLeft = parseSpacingValue(props.pl ?? props.px);
    const paddingRight = parseSpacingValue(props.pr ?? props.px);
    const paddingTop = parseSpacingValue(props.pt ?? props.py);
    const width = parseSpacingValue(props.w);
    return {
        ...(height !== undefined && {
            height: height === 'full' ? '100%' : height,
        }),
        ...(margin !== undefined && { margin }),
        ...(marginBottom !== undefined && { marginBottom }),
        ...(marginLeft !== undefined && { marginLeft }),
        ...(marginRight !== undefined && { marginRight }),
        ...(marginTop !== undefined && { marginTop }),
        ...(maxHeight !== undefined && {
            maxHeight: maxHeight === 'full' ? '100%' : maxHeight,
        }),
        ...(maxWidth !== undefined && {
            maxWidth: maxWidth === 'full' ? '100%' : maxWidth,
        }),
        ...(padding !== undefined && { padding }),
        ...(paddingBottom !== undefined && { paddingBottom }),
        ...(paddingLeft !== undefined && { paddingLeft }),
        ...(paddingRight !== undefined && { paddingRight }),
        ...(paddingTop !== undefined && { paddingTop }),
        ...(width !== undefined && { width: width === 'full' ? '100%' : width }),
    };
};
const parseSpacingValue = (value) => {
    if (value === undefined) {
        return undefined;
    }
    else if (typeof value === 'string') {
        return value;
    }
    return `${theme_1.theme.spacing[value]}px`;
};
const createBaseStyles = (props = {}) => {
    const { columnGap, flex, gap, h, m, maxH, maxW, mb, ml, mr, mt, mx, my, p, pb, pl, pr, pt, px, py, rounded, rowGap, w, ...rest } = props;
    const styles = {
        ...parseBorderShorthandProps({ rounded }),
        ...parseFlexProps({ flex }),
        ...parseGapProps({ columnGap, gap, rowGap }),
        ...parseSpacingShorthandProps({
            h,
            m,
            maxH,
            maxW,
            mb,
            ml,
            mr,
            mt,
            mx,
            my,
            p,
            pb,
            pl,
            pr,
            pt,
            px,
            py,
            w,
        }),
        ...rest,
    };
    return styles;
};
/**
 * Utility function for creating CSS styles.
 * Adds support for some Tailwind-style shorthand props.
 */
const createStyles = (props = {}) => {
    return createBaseStyles(props);
};
exports.createStyles = createStyles;
