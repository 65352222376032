"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHIFT_ACTIVITY_COLORS = void 0;
const enums_1 = require("@bemlo/enums");
const constants_1 = require("../../constants");
exports.SHIFT_ACTIVITY_COLORS = {
    [enums_1.ShiftActivity.FLEX]: {
        badge: 'yellow',
        background: constants_1.Color.LIGHT_YELLOW,
        icon: 'LightningBoltIcon',
        iconColor: constants_1.Color.YELLOW,
    },
    [enums_1.ShiftActivity.TEAM_MEETING]: {
        badge: 'orange',
        background: constants_1.Color.ORANGE,
        icon: 'HomeIcon',
        iconColor: constants_1.Color.ORANGE,
    },
    [enums_1.ShiftActivity.TRAINING]: {
        badge: 'violet',
        background: constants_1.Color.VIOLET,
        icon: 'BookOpenIcon',
        iconColor: constants_1.Color.VIOLET,
    },
    [enums_1.ShiftActivity.WORK]: {
        badge: 'blue',
        background: constants_1.Color.LIGHT_BLUE,
        icon: 'SunIcon', // Hmm...
        iconColor: constants_1.Color.BLUE,
    },
    [enums_1.ShiftActivity.INTRODUCTION]: {
        badge: 'green',
        background: constants_1.Color.LIGHT_GREEN,
        icon: 'CubeIcon',
        iconColor: constants_1.Color.GREEN,
    },
    [enums_1.ShiftActivity.OTHER]: {
        badge: 'red',
        background: constants_1.Color.MEDIUM_BLUE,
        icon: 'ClipboardIcon',
        iconColor: constants_1.Color.DARK_BLUE,
    },
};
