"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentPageSidebarHeader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../constants");
const Flex_1 = require("../Flex");
const ContentPageSidebarHeader = (props) => {
    return ((0, jsx_runtime_1.jsx)(Flex_1.Flex, { styles: {
            alignItems: 'center',
            borderBottom: constants_1.theme.border.style.thinLight,
            minHeight: 42,
            px: 3,
            ...props.styles,
        }, children: props.children }));
};
exports.ContentPageSidebarHeader = ContentPageSidebarHeader;
