"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentPageSidebar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Header_1 = require("./Header");
const Item_1 = require("./Item");
const Section_1 = require("./Section");
const constants_1 = require("../../constants");
const Div_1 = require("../Div");
const ContentPageSidebar = (props) => {
    return ((0, jsx_runtime_1.jsx)(Div_1.Div, { styles: {
            background: constants_1.Color.WHITE,
            height: '100vh',
            position: 'sticky',
            top: 0,
            width: 320,
            overflow: 'auto',
            borderLeft: constants_1.theme.border.style.thinLight,
            zIndex: constants_1.theme.zIndex.contentpagesidebar,
        }, children: props.children }));
};
exports.ContentPageSidebar = ContentPageSidebar;
ContentPageSidebar.Header = Header_1.ContentPageSidebarHeader;
ContentPageSidebar.Item = Item_1.ContentPageSidebarItem;
ContentPageSidebar.Section = Section_1.ContentPageSidebarSection;
