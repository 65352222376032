"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftActivity = void 0;
const enums_1 = require("@bemlo/enums");
exports.shiftActivity = {
    [enums_1.ShiftActivity.WORK]: 'Ordinarie',
    [enums_1.ShiftActivity.FLEX]: 'Resurspass',
    [enums_1.ShiftActivity.TEAM_MEETING]: 'APT',
    [enums_1.ShiftActivity.TRAINING]: 'Utbildning',
    [enums_1.ShiftActivity.INTRODUCTION]: 'Introduktion',
    [enums_1.ShiftActivity.OTHER]: 'Övrigt',
};
